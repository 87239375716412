import React from 'react'

const Header = ({ hours, toggleHours, hoursOpen }) => {
  const openHours = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const parseHours = (hourObj) => {
    if (!hourObj) {
      return ''
    }

    const {
      open,
      close
    } = hourObj

    const openTime = new Date(`1970-01-01T${open}:00Z`)
    const closeTime = new Date(`1970-01-01T${close}:00Z`)
    const options = { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }

    // Removing the space between '9' and 'AM'
    const openString = openTime.toLocaleTimeString('en-US', options).split(' ').join('')
    const closeString = closeTime.toLocaleTimeString('en-US', options).split(' ').join('')

    return `${openString} - ${closeString}`
  }

  const getHours = (date) => {
    const holidayHours = hours.find(h => {
      const {
        holidaystart = '',
        holidayend = ''
      } = h

      if (!holidaystart && !holidayend) {
        return false
      }

      const startDate = new Date(holidaystart)
      const endDate = new Date(holidayend)

      endDate.setTime(endDate.getTime() + (24 * 60 * 60 * 1000))

      return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
    })

    if (holidayHours) {
      return holidayHours
    }

    const myDay = date.toLocaleString('en-us', { weekday: 'long' }).toLowerCase()
    const regularHours = hours.find(h => h.name.toLowerCase() === myDay)

    if (regularHours) {
      return regularHours
    }

    return false
  }

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  const hourArray = daysOfWeek.map(day => {
    const myDay = new Date().toLocaleString('en-us', { weekday: 'long' }).toLowerCase()
    const hourObj = hours.find(h => h.name.toLowerCase() === day.toLowerCase())

    return {
      name: day,
      date: hourObj,
      isActive: myDay === day.toLowerCase()
    }
  })

  const holidays = hours
    .filter(h => !daysOfWeek.includes(h.name) && h.holidaystart && new Date() < new Date(h.holidaystart))
    .map(h => {
      const {
        holidaystart,
        holidayend
      } = h

      const startDate = new Date(holidaystart)
      const endDate = new Date(holidayend)

      const dateString = holidayend ?
        startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) :
        startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) + ' - ' + endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })

      return {
        name: h.name,
        timeString: parseHours(h),
        dateString
      }
    })

  return (
    <div className='header'>
      <div className='header-wrapper'>
        <img src='/icons/yrh-full.svg' alt='youRhere Logo' />
        <h1>3D Map Demo</h1>
        <div className='hours' onClick={toggleHours}>
          <h2>Open Today: </h2>
          <h3>{parseHours(getHours(new Date()))}</h3>
          <img src={`/icons/chevron-${hoursOpen ? 'up' : 'down'}.svg`} alt='' />
        </div>
        {
          hoursOpen ? (
            <div className='fullHours' onClick={openHours}>
              <div className='hoursItem'>
                <h2>Regular Shopping Hours</h2>
                <ul className='hourList'>
                  {hourArray.map(h => (
                    <li className={h.isActive ? 'active' : ''} key={h.name}>
                      <div className='name'>{h.name}</div>
                      <div className='data'>{parseHours(h.date)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              {holidays.length ? (
                <div className='hoursItem'>
                  <h2>Holiday Hours</h2>
                  <ul className='hourList'>
                    {holidays.map(h => (
                      <li className={h.isActive ? 'active' : ''}>
                        <div className='name'>
                          <strong>{h.name}</strong>
                          <div>{h.dateString}</div>
                        </div>
                        <div className='data'>{h.timeString}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className='hoursItem'>
                <h3 className='footnote'>Powered By <img src='/icons/yrh-full.svg' alt='youRhere Logo' /></h3>
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default Header