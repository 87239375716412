import React, { useState } from 'react'

import '../css/SearchBar.scss'

const SearchBar = ({
  tenants,
  categories,
  activeTenant,
  terminalID,
  navigateToTenant,
  changeRoute,
  resetMap,
  isMobile
}) => {
  const [searchString, setSearchString] = useState('')
  const [searchFocus, setSearchFocus] = useState(false)
  const [originString, setOriginString] = useState('')
  const [originFocus, setOriginFocus] = useState(false)
  const [destinationFocus, setDestinationFocus] = useState(false)
  const [destinationString, setDestinationString] = useState('')

  const [useCats, setUseCats] = useState(false)
  const [category, setCategory] = useState()
  const [origin, setOrigin] = useState()
  const [destination, setDestination] = useState()

  const showBackButton = useCats || activeTenant || origin || destination
  const showReset = true

  const normalizeString = (val) => {
    if (!['string', 'number'].includes(typeof val)) {
      return ''
    }

    const stringVal = val.toString()
    const noAccents = stringVal.normalize('NFD').replace(/\p{Diacritic}/gu, '')
    const upperCase = noAccents.toUpperCase()

    return upperCase
  }

  const getMatchingTenants = (searchValue) => {
    let matchingTenants = []
    const normalString = normalizeString(searchValue)

    if (typeof searchValue === 'boolean' && searchValue === true) {
      matchingTenants = tenants
    } else if (searchValue) {
      matchingTenants = tenants.filter(t => normalizeString(t.name).includes(normalString))
    } else if (category) {
      matchingTenants = tenants.filter(t => t.category.includes(category.label_id))
    }

    matchingTenants = matchingTenants.filter(t => (!origin && !destination) || (t !== origin && t !== destination))

    // Sort alphabetically first
    matchingTenants.sort((a, b) => a.name.localeCompare(b.name))

    // Then move exact matches to the front
    matchingTenants.sort((a, b) => {
      if (!normalString) {
        return 0
      }

      const aName = normalizeString(a.name)
      const bName = normalizeString(b.name)

      const aStart = aName.startsWith(normalString)
      const bStart = bName.startsWith(normalString)

      if (aStart && !bStart) {
        return -1
      }

      if (bStart && !aStart) {
        return 1
      }

      return 0
    })

    return matchingTenants
  }

  const resetSearch = () => {
    setSearchString('')
    setOriginString('')
    setDestinationString('')
    setCategory()
    setUseCats(false)
    navigateToTenant(null)
    setOrigin(null)
    setDestination(null)
    setSearchFocus(false)
    setOriginFocus(false)
    setDestinationFocus(false)
  }

  const fullReset = () => {
    resetSearch()
    resetMap()
  }

  const setTenant = (t) => {
    resetSearch()
    navigateToTenant(t)
  }

  const selectDestination = (t) => {
    navigateToTenant(null)
    setDestination(t)
    focusBlurInput('origin', 'focus')
    setDestinationString('')
    setDestinationFocus(false)
    changeRoute(t, 'destination')
  }

  const selectOrigin = (t) => {
    navigateToTenant(null)
    setOrigin(t)
    setOriginString('')
    setOriginFocus(false)
    changeRoute(t, 'origin')
  }

  const swapRoute = () => {
    const originCopy = origin ? { ...origin } : null
    const destinationCopy = destination ? { ...destination } : null

    if (origin && origin.isScannedLocation) {
      if (destinationCopy) {
        selectOrigin(destinationCopy)
        selectDestination(null)
      }

      return
    }

    selectDestination(originCopy)
    selectOrigin(destinationCopy)
  }

  const goBack = () => {
    if (activeTenant) {
      resetSearch()
      navigateToTenant(null)
    } else if ((useCats && !category)) {
      resetSearch()
    } else if (origin || destination) {
      navigateToTenant(origin || destination)
      setDestination(null)
      setOrigin(null)
    } else {
      setCategory()
    }
  }

  const focusBlurInput = (id, event, realEvent) => {
    if (realEvent) {
      realEvent.persist()
      
      const { relatedTarget } = realEvent
      
      if (relatedTarget && relatedTarget.className === 'resultEntry') {
        return
      }
    } else {
      const lookup = {
        origin: {
          focus: () => setOriginFocus(true),
          blur: () => setOriginFocus(false)
        },
        destination: {
          focus: () => setDestinationFocus(true),
          blur: () => setDestinationFocus(false)
        },
        search: {
          focus: () => setSearchFocus(true),
          blur: () => setSearchFocus(false)
        }
      }
  
      if (lookup[id] && lookup[id][event]) {
        lookup[id][event]()
      }
    }
  }

  const handleInputChange = (id, value) => {
    const lookup = {
      origin: () => setOriginString(value),
      destination: () => setDestinationString(value),
      search: () => setSearchString(value)
    }

    if (lookup[id]) {
      if (value) {
        focusBlurInput(id, 'blur')
      } else {
        focusBlurInput(id, 'focus')
      }

      lookup[id]()
    }
  }

  const getSearchContent = () => {
    if (origin && destination) {
      return (<>
        <div className='inputWrapper route'>
          <div className='routeWrapper'>
            <div>
              <img
                  src='/icons/place.svg'
                  alt=''
              />
              {origin.name}
            </div>
            <div>
              <img
                  src='/icons/destination.svg'
                  alt=''
              />
              {destination.name}
            </div>
          </div>
          <button onClick={fullReset}><img src='/icons/clear.svg' alt='Clear input'/></button>
        </div>
      </>)
    }

    if (useCats) {
      return (<>
        <img
          src={`/icons/${showBackButton ? 'arrow-left' : 'magnify'}.svg`}
          alt=''
          onClick={showBackButton ? goBack : null}
        />
        <div className='inputWrapper'>
          <h3>{(category && category.name) || 'Categories'}</h3>
          {showReset ? <button onClick={resetSearch}><img src='/icons/clear.svg' alt='Clear input'/></button> : null}
        </div>
      </>)
    }

    if (activeTenant) {
      return (<>
        <img
          src='/icons/arrow-left.svg'
          alt=''
          onClick={goBack}
        />
        <div className='inputWrapper'>
          <h3>{activeTenant.name}</h3>
          {showReset ? <button onClick={resetSearch}><img src='/icons/clear.svg' alt='Clear input'/></button> : null}
        </div>
      </>)
    }

    if (origin || destination) {
      const originContent = origin ? (<>
        <h3>{origin.name}</h3>
        <img
          src='/icons/switch.svg'
          alt='Swap origin and destination'
          onClick={swapRoute}
        />
        <button onClick={() => selectOrigin(null)}><img src='/icons/clear.svg' alt='Clear input'/></button>
      </>) : (<>
        <input
          name='originSearch'
          value={originString}
          onChange={(e) => handleInputChange('origin', e.target.value)}
          onFocus={() => focusBlurInput('origin', 'focus')}
          onBlur={(e) => focusBlurInput('origin', 'blur', e)}
          placeholder='SELECT ORIGIN'
          autoComplete='off'
        />
        <img
          src='/icons/switch.svg'
          alt='Swap origin and destination'
          onClick={swapRoute}
          className={originString ? '' : 'onlyButton'}
        />
        {originString ? <button onClick={() => selectOrigin(null)}><img src='/icons/clear.svg' alt='Clear input'/></button> : null}
      </>)

      const destinationContent = destination ? (<>
        <h3>{destination.name}</h3>
        <button onClick={() => selectDestination(null)}><img src='/icons/clear.svg' alt='Clear input'/></button>
      </>) : (<>
        <input
          name='destinationSearch'
          value={destinationString}
          onChange={(e) => handleInputChange('destination', e.target.value)}
          onFocus={() => focusBlurInput('destination', 'focus')}
          onBlur={(e) => focusBlurInput('destination', 'blur', e)}
          placeholder='SELECT DESTINATION'
          autoComplete='off'
        />
        {destinationString ? <button onClick={() => selectDestination(null)}><img src='/icons/clear.svg' alt='Clear input'/></button> : null}
      </>)

      return (<div className='directions'>
        <div className='origin'>
          <img
            src={`/icons/${showBackButton ? 'arrow-left' : 'magnify'}.svg`}
            alt=''
            onClick={showBackButton ? goBack : null}
          />
          {originContent}
        </div>
        <div className='destination'>
          {destinationContent}
        </div>
      </div>)
    }

    return (<>
      <img
        src='/icons/magnify.svg'
        alt=''
      />
      <div className='inputWrapper'>
        <input
          name='search'
          value={searchString}
          onChange={ (e) => handleInputChange('search', e.target.value) }
          onFocus={ () => focusBlurInput('search', 'focus') }
          onBlur={ (e) => focusBlurInput('search', 'blur', e) }
          placeholder='SEARCH STORES'
          autoComplete='off'
        />
        {
          searchFocus || searchString
            ? <button onClick={resetSearch}><img src='/icons/clear.svg' alt='Clear input'/></button>
            : <button onClick={() => setUseCats(true)}><img src='/icons/categories.svg' alt='Use categories'/></button>
        }
      </div>
    </>)
  }

  const getResultContent = () => {
    if (useCats && !category) {
      return categories
        .filter(c => tenants.find(t => t.category.includes(c.label_id)))
        .map(c => (
          <button
            className='resultEntry category'
            key={c._id}
            onClick={() => setCategory(c)}
          >
            <div>{c.name.toUpperCase()}</div>
          </button>
        ))
    }

    if (activeTenant) {
      const {
        category = [],
        name,
        fileid,
        phone
      } = activeTenant

      const tenantCategories = category.map(c => categories.find(cat => cat.label_id === c).name)

      return (<div className='tenantBox'>
        <div className='tenantBoxHeader'>
          <img
            src={fileid ? '' : '/icons/logo.svg'}
            alt={`Logo for ${name}`}
          />
          <span>{name}</span>
          <button onClick={() => selectDestination(activeTenant)}>
            Directions <img src='/icons/directions.svg' alt='' />
          </button>
        </div>
        <div className='tenantBoxBody'>
          <div className='info'>
            <strong>Category</strong>
            <div>{tenantCategories.join(', ')}</div>
          </div>
          {
            phone && phone !== '' &&
              <div className='info'>
                <strong>Phone</strong>
                <div>{phone || '-'}</div>
              </div>
          }
        </div>
      </div>)
    }

    if (origin || destination) {
      const results = getMatchingTenants(originFocus || destinationFocus || originString || destinationString).map(t => {
        const onClick = originFocus || originString ? () => selectOrigin(t) : () => selectDestination(t)

        return (
          <button
            className='resultEntry'
            key={t._id}
            onClick={onClick}
          >
            {t.fileid ? <img src={`https://cms-v2.yourhere.ca/containers/${t.fileid}`} alt={`Logo for ${t.name}`} /> : null}
            <div>{t.name}</div>
          </button>
        )
      })

      if (terminalID && (originFocus || originString)) {
        results.unshift(
          <button
            className='resultEntry'
            key='scannedLocation'
            onClick={() => selectOrigin({ name: 'Scanned Location', isScannedLocation: true })}
          >
            <img src='/icons/qr.svg' alt='' />
            <div>Scanned Location</div>
          </button>
        )
      }

      return results
    }

    return getMatchingTenants(searchFocus || searchString).map(t => (
      <button
        className='resultEntry'
        key={t._id}
        onClick={() => setTenant(t)}
      >
        {t.fileid ? <img src={`https://cms-v2.yourhere.ca/containers/${t.fileid}`} alt={`Logo for ${t.name}`} /> : null}
        <div>{t.name}</div>
      </button>
    ))
  }

  return (
    <>
      <div className='resultsWrapper'>
        <div className='results'>
          {getResultContent()}
        </div>
      </div>
      <div className={`search ${origin || destination ? 'directions' : ''} ${isMobile ? 'mobile' : 'desktop'}`}>
        {getSearchContent()}
      </div>
    </>
  )
}

export default SearchBar