import React, { useState, useEffect } from 'react';
import SearchBar from './components/SearchBar'
import Header from './components/Header'
import Map from './components/Map'
import FloorSelect from './components/FloorSelect'

// import ListView from './components/ListView'
// import TenantView from './components/TenantView'
// import { ReactComponent as MapIcon } from './icons/map-solid.svg'
// import { ReactComponent as CaretOpenIcon } from './icons/caret-down.svg'
// import { ReactComponent as CloseIcon } from './icons/close.svg'
// import { ReactComponent as ListIcon } from './icons/list-ul-solid.svg'

import './css/App.scss'
import './css/3dMap.scss'
import './fonts/SourceSans3-VariableFont_wght.ttf'
import './fonts/CeraPro-Black.ttf'
import './fonts/CeraPro-Medium.ttf'
import './fonts/CeraPro-Regular.ttf'

import config from './map-config.json'

function App() {
  const [hoursOpen, setHoursOpen] = useState(false)
  const [tenantData, setTenantData] = useState()
  const [hourData, setHourData] = useState()
  const [terminalID, setTerminalID] = useState('')
  const [activeTenant, setActiveTenant] = useState()
  const [activeFloor, setActiveFloor] = useState('')
  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  
  useEffect(() => {
    const baseURL = 'https://cms-v2.yourhere.ca'
    const accountid = '62e014d0259243293c7f395e'
    const key = 'PPVpXbU.NWo-T7soPgwj6hGe'
    // const baseURL = 'http://localhost:80'
    // const accountid = '63c1f047cfa7ac4c74209dab'
    // const key = 'DjB-WsfnV7Y_4_i4HdzemOQY'

    const getHourData = async () => {
      try {
        const hoursRes = await fetch(`${baseURL}/v1/mapdata/hours/${accountid}`, {
          method: 'GET',
          headers: {
            'yrh-integration-api-key': key
          }
        })

        const hourData = await hoursRes.json()

        if (hourData) {
          setHourData(hourData)
        }
      } catch (err) {
        console.error(err)
      }
    }

    const getTenantData = async () => {
      try {
        const tenantRes = await fetch(`${baseURL}/v1/mapdata/tenants/${accountid}`, {
          method: 'GET',
          headers: {
            'yrh-integration-api-key': key
          }
        })

        const tenantData = await tenantRes.json()

        if (tenantData) {
          // console.log(JSON.stringify(tenantData))
          setTenantData(tenantData)
        }
      } catch (err) {
        console.error(err)
      }
    }

    if (!tenantData) {
      getTenantData()
    }

    if (!hourData) {
      getHourData()
    }

    const dID = new URLSearchParams(document.location.search).get('d')

    if (dID) {
      setTerminalID(dID)
    }
  }, [tenantData, hourData])

  useEffect(() => {
    const e = window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 768)
    })

    return () => {
      window.removeEventListener('resize', e)
    }
  }, [])

  if (!tenantData || !hourData || loading) {
    return (
      <div className='loading'>
        <img src='/icons/yrh-full.svg' alt='youRhere Logo' />
      </div>
    )
  }

  const closeDialogs = () => {
    if (hoursOpen) {
      setHoursOpen(false)
    }
  }

  const toggleHours = (e) => {
    setHoursOpen(!hoursOpen)
    e.preventDefault()
    e.stopPropagation()
  }

  const navigateToTenant = (t) => {
    setActiveFloor('')
    setActiveTenant(t)
  }

  const changeFloor = (f) => {
    navigateToTenant(null)
    setActiveFloor(f)
  }

  const resetMap = () => {
    setLoading(true)
    setOrigin('')
    setDestination('')
    setActiveFloor('')
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  const changeRoute = (t, field) => {
    const {
      suite: {
        name = ''
      } = {},
      isScannedLocation
    } = t || {}

    if (isScannedLocation && terminalID) {
      if (field === 'origin') {
        setOrigin(terminalID)
      }
      return
    }

    if (field === 'origin') {
      setOrigin(name)
    } else if (field === 'destination') {
      setDestination(name)
    }
  }

  const filteredTenants = tenantData.tenants.filter(t => {
    const {
      suite: {
        name
      }
    } = t

    if (!name) {
      return false
    }

    const suiteInConfig = config.shops.find(s => s.cmsID && s.cmsID.toLowerCase() === name.toLowerCase())

    return suiteInConfig
  })

  if (origin && destination && activeFloor !== '') {
    console.log('on changeRoute, set active floor to blank')
    setActiveFloor('')
  }

  return (
    <div className='app' onClick={closeDialogs}>
      <Header
        hours={hourData.hours || []}
        hoursOpen={hoursOpen}
        toggleHours={toggleHours}
      />
      <div className='body'>
        <div className={ `bodyWrapper ${isMobile ? 'mobile' : 'desktop'}` }>
          {origin && destination ? null : <FloorSelect
            activeFloor={activeFloor}
            setActiveFloor={changeFloor}
          />}
          <Map
            tenants={tenantData.tenants}
            activeFloor={activeFloor}
            activeTenant={activeTenant && activeTenant.suite ? activeTenant.suite.name : null}
            config={config}
            origin={origin}
            destination={destination}
            isMobile={isMobile}
          />
        </div>
      </div>
      <div className='footer'>
        <SearchBar
          tenants={filteredTenants || []}
          categories={tenantData.categories || []}
          activeTenant={activeTenant}
          terminalID={terminalID}
          navigateToTenant={navigateToTenant}
          changeRoute={changeRoute}
          resetMap={resetMap}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}

export default App;
