import React, { useState } from 'react'

const floorDict = {
  main_level: 'MainLevel',
  upper_level: 'UpperLevel',
  parking: 'ParkadeLevel'
}

const FloorSelect = ({ activeFloor, setActiveFloor }) => {
  const [isOpen, setOpen] = useState(false)
  const floorEntry = Object.entries(floorDict).find(([_, mapId]) => mapId === activeFloor)
  const activeFloorName = floorEntry ? floorEntry[0] : 'All Floors'

  const changeFloor = (f) => {
    setOpen(false)
    setActiveFloor(f)
  }
  
  return (
    <div className='floorSelect'>
      <div className='selectedValue' onClick={() => setOpen(!isOpen)}>
        <span>{ activeFloorName.split('_').join(' ') }</span>
        <span>{ isOpen ? '▲' : '▼' }</span>
      </div>
      {isOpen ? (<div className='dropdown'>
        <div className={ `option ${!floorEntry && 'active'}` } onClick={() => setActiveFloor('')}>All Floors</div>
      {
        Object.entries(floorDict).map(([cmsId, mapId]) => (
          <div className={ `option ${cmsId === activeFloorName && 'active'}` } value={mapId} key={mapId} onClick={() => changeFloor(mapId)}>{ cmsId.split('_').join(' ') }</div>
        ))
      }
      </div>) : null}
    </div>
  )
}

export default FloorSelect