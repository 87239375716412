import React from 'react'
import { Map3D } from '@yourhere/yourhere-core'
import { Config, SceneWrapper } from '@yourhere/yourhere-3dmapping'

const Map = ({
  tenants,
  activeFloor,
  activeTenant,
  clickHandler,
  terminalID,
  config,
  origin,
  destination,
  isMobile
}) => {

  const route = origin && destination ? {
    start: origin,
    end: destination
  } : null

  return (
    <Map3D
      babylonFilepath='/models/map.babylon'
      mapping={{
        config: Config,
        sceneWrapper: SceneWrapper
      }}
      activeFloor={activeFloor}
      activeTenant={activeTenant}
      terminalID={terminalID}
      route={route}
      config={config}
      tenantData={tenants}
      options={{
        hideControls: false,
        hideRotateControls: true,
        hideNorthMarker: false,
        showRouteSelector: false,
        clickToHighlight: !!clickHandler,
        orthoScale: 0.05,
        cameraBeta: Math.PI / 4,
        upperZoomLimit: isMobile ? 0.25 : 0.075,
        lowerZoomLimit: 0.003,
        disableRotationX: false,
        disableRotationY: false,
        logoKey: 'maplogo',
        labelFont: 'Cera Pro',
        centerActiveTenant: true,
        northMarker: './icons/north.svg',
        clickHandler,
        floorLabelOffsetMult: -1.4,
        floorLabels: {
          'UpperLevel': 'Upper Level',
          'MainLevel': 'Main Level',
          'ParkadeLevel': 'Parkade Level'
        }
      }}
      primaryHighlight="#e7c7af"
      secondaryHighlight="#ffd280"
      backgroundColor="#EEEEF0"
    />
  )
}

export default Map